import React from 'react';
import '../home/home.css';

const Video = () => {
    return (
        <>
            <section className="hero d-flex align-items-center">
                    <div className="row headers">
                        
                        <img src="assets/web_solution_firm.webp" className="img-fluid headerImg" alt="logo"/>
                        
                        <div className="overlays">
                            <h2 className='titleLogo text-center'>
                                <img src="assets/logo/logo.png" className="img-fluid" alt="logo"/>
                                {/* <span className='border1'>
                                    <span className='border2'>WEB SOLUTION FIRM</span>
                                </span> */}
                            </h2>   
                            <p className='text-center subContent'> <span className='bgColor'>LIFTING YOUR BUSINESS FROM GARAGE TO GLOBAL</span> </p>
                        </div>
                    </div>
            </section>
        </>
    );
};

export default Video;
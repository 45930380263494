import React from 'react';
import '../home.css';
import { BsPencilSquare } from "react-icons/bs";
import { IoIosCloudUpload } from "react-icons/io";
import { CgCodeSlash } from "react-icons/cg";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import { FaLaptopCode } from "react-icons/fa";
import { SiGraphql } from "react-icons/si";


const Newsletter = () => {
    return (
        <>
            <div className="container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s" style={{ paddingTop:'20px',paddingBottom:'50px' }}>
                
                <header className="section-header">
                    <p className="text-white white-un">Our Work Process</p>
                    <h3 className="text-white workProcessText">We are a very flexible and agile team. Working with us, developers or designers, or partner with us along your entire journey from fundamental bits to a beating reality.</h3>
                </header>
                <div className="row gy-4">
                    <div className="col-lg-2 col-md-2 col-sm-4 col-6" data-aos="fade-up" data-aos-duration="1" data-aos-delay="0.1s">
                        <div className='process'></div>
                        <div className="our-work blue bounceClass">
                            <i className="icon text-center">
                                <BsPencilSquare />
                            </i>
                            <h4 className='text-white'>Initiation</h4>
                            {/* <p className='text-white'>Research Planning and System Goals</p> */}
                        </div>                        
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-4 col-6" data-aos="fade-up" data-aos-duration="1" data-aos-delay="0.1s">
                        <div className='process'></div>
                        <div className="our-work orange bounceClass">
                            <i className="icon text-center">
                                <FaLaptopCode />
                            </i>
                            <h4 className='text-white'>Design </h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-4 col-6" data-aos="fade-up" data-aos-duration="1" data-aos-delay="0.1s">
                        <div className='process'></div>
                        <div className="our-work green bounceClass">
                            <i className="icon text-center">
                                <SiGraphql />
                            </i>
                            <h4 className='text-white'>Architecture </h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-4 col-6" data-aos="fade-up" data-aos-duration="1" data-aos-delay="0.1s">
                        <div className='process'></div>
                        <div className="our-work red bounceClass">
                            <i className="icon text-center">
                                <CgCodeSlash />
                            </i>
                            <h4 className='text-white'>Development </h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-4 col-6" data-aos="fade-up" data-aos-duration="1" data-aos-delay="0.1s">
                        <div className='process'></div>
                        <div className="our-work purple bounceClass">
                            <i className="icon text-center">
                                <MdOutlineScreenSearchDesktop />
                            </i>
                            <h4 className='text-white'>Testing </h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-4 col-6" data-aos="fade-up" data-aos-duration="1" data-aos-delay="0.1s">
                        <div className="our-work deep-blue bounceClass">
                            <i className="icon text-center">
                                <IoIosCloudUpload />
                            </i>
                            <h4 className='text-white'>Deployment</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Newsletter;
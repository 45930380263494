import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../api/api";
import config from "../../../config";

function BlogSidebar({ blog }) {
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    api.get(`/blogs/${'blog'}/blog-latest`).then((res) => {
      setLatestBlogs(res.data.data.latest_blogs);
    });

    api.get(`/blogs/${'blog'}/blog-categories`).then((res) => {
      setCategories(res.data.data.categories);
    });

    api.get(`/blogs/${'blog'}/blog-tags`).then((res) => {
      setTags(res.data.data.tags);
    });
  }, []);
  
  return (
    <div className="sidebar">
      <h3 className="sidebar-title">
        <Link to={`/company/blogs`}>
          Categories
        </Link>
      </h3> <hr />
      <div className="sidebar-item categories">
        <ul>
          {categories.map((category) => (
            <li key={category.id}>
              <Link to={`/company/blogs/category/${category.slug}`}>
                {category.name} <span></span>
              </Link>
              <div>
                {category.child && category.child.length > 0 ? (
                  category.child.map((childCategory) => (
                    <Link
                      to={`/company/blogs/category/${childCategory.slug}`}
                      className="child-category d-block"
                      key={`${childCategory.id}`}
                    >
                      --- {childCategory.name} <span></span>
                    </Link>
                  ))
                ) : (
                  <span></span>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>

      <h3 className="sidebar-title mt-4">Recent Posts</h3> <hr />
      <div className="sidebar-item recent-posts">
        {latestBlogs.map((latestBlog) => (
          <div key={latestBlog.id} className="post-item clearfix">
            <img
              src={`${config.IMAGE_BASE_PATH}${latestBlog ? latestBlog.featured_image : ''}`}
              alt={latestBlog && latestBlog.title}
            />
            <h4>
              <Link to={`/company/blogs/details/${latestBlog.slug}`}>{latestBlog.title}</Link>
            </h4>
            <time>{latestBlog.created_at}</time>
          </div>
        ))}
      </div>

      <h3 className="sidebar-title mt-4">Tags</h3> <hr />
      <div className="sidebar-item tags">
        <ul>
          {tags.length > 0 &&
            tags.map((tag) => (
              <li key={tag.id}>
                <Link to="#">{tag.name}</Link>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default BlogSidebar;

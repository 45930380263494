import React from 'react';
import '../../home.css';

const AddressNz = () => {
    return (
        <>
            <div className="info-box" style={{ backgroundColor: "var(--color-light-blue-opacity)" }}>
                <h3 className="text-white">New Zealand (Head Quater): </h3>
                <p className="text-white" style={{ fontSize: "16px" }}>
                    <i className="bi bi-telephone text-white" style={{ fontSize: "16px", paddingRight:"15px" }}></i> 
                    +642102362222
                </p>
                <p className="text-white" style={{ fontSize: "16px" }}>
                    <i className="bi bi-envelope text-white" style={{ fontSize: "16px", paddingRight:"15px" }}></i> 
                    masum@websolutionfirm.com
                </p>
                <p className="text-white" style={{ fontSize: "16px" }}>
                    <i className="bi bi-geo-alt text-white" style={{ fontSize: "16px", paddingRight:"15px" }}></i> 
                    29/A Layard Street, Avondale, Auckland 1026
                </p>
            </div>
        </>
    );
};

export default AddressNz;
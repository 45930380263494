import React from 'react';
import '../../home.css';
import AddressBd from './AddressBd';
import AddressNz from './AddressNz';
import ContactForm from './ContactForm';


const ContactUs = () => {
    return (
        <>
            <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                <div className="row gy-4">
                    <div className="col-lg-6">
                        <ContactForm />
                    </div>

                    <div className="col-lg-6">
                        <div className="row gy-4">
                            <header className="section-header">
                                <p className="text-white">DO YOU HAVE A GREAT IDEA? WE CAN MAKE IT A REALITY</p>
                                <p className="text-white" style={{ paddingTop:'20px', marginBottom:'-15px' }}>
                                    <span style={{ backgroundColor: "var(--color-light-blue-opacity)", padding:"5px 10px" }}>
                                        Our Address
                                    </span>
                                </p>
                            </header>
                            <div className="col-md-12">
                                <AddressNz />
                            </div>
                            <div className="col-md-12">
                                <AddressBd />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactUs;
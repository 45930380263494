import React, { useEffect, useState } from "react"
import api from "../../../api/api";
import config from "../../../config";
import '../../../home/home.css'

import NoImage from '../../../assets/images/noimage.jpg'

const TestimonialPageDetails = ({ setProgress }) => {
    const [testimonials, setTestimonials] = useState([]);
    useEffect(() => {
        setProgress(10);

        api.get(`/testimonials/pages`).then((res) => {
            if (res.status === 200) {
                setTestimonials(res.data.data);
            }

            setProgress(100);
        });
    }, []);

    return (
        <> 
            <div className=''>
                <div className="container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                    <div className="testimonials-slider row" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                        {
                            testimonials.map((item, idx) => (
                                <div className="team col-lg-4" key={idx}>
                                    <div className="testimonial-item">
                                        <div className="member">
                                            <div className="member-img">
                                                <img 
                                                    className="img-fluid" 
                                                    alt={item.name}
                                                    src={item.image ? `${config.IMAGE_BASE_PATH}${item.image}` : NoImage}
                                                />
                                            </div>
                                            <div className="member-info">
                                                <h4>{item.name}</h4>
                                                <span>{item.designation}</span>
                                                <p dangerouslySetInnerHTML={{ __html: item.note }}></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                            
                    </div>
                </div>
            </div>
        </>
    )
}
export default TestimonialPageDetails
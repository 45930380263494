const PortfolioItem=[
    {
        id:1,
        cat:'webapp',
        title:'Web Application',
        desc:'Web Application Description', 
        laptop: 'assets/img/portfolio/main-laptop-iframe7.png',
        image: 'assets/img/portfolio/webApp/cms.jpg'
    },
    {
        id:2,
        cat:'mobileapp',
        title:'Mobile Application',
        desc:'Mobile Application Description', 
        laptop: 'assets/img/portfolio/mob.png',
        image: 'assets/img/portfolio/mobileApp/eCommerce.jpg'
    },
    {
        id:3,
        cat:'creative',
        title:'Creative Application',
        desc:'Creative Application Description', 
        laptop: 'assets/img/portfolio/main-laptop-iframe7.png',
        image: 'assets/img/portfolio/creative/logo.jpg'
    },
    {
        id:4,
        cat:'dataanalytics',
        title:'Data Analytics Application',
        desc:'Data Analytics Application Description', 
        laptop: 'assets/img/portfolio/main-laptop-iframe7.png',
        image: 'assets/img/portfolio/web.png'
    },
    {
        id:5,
        cat:'webapp',
        title:'Web Application',
        desc:'Web Application Description', 
        laptop: 'assets/img/portfolio/main-laptop-iframe7.png',
        image: 'assets/img/portfolio/webApp/eCommerce.jpg'
    },
    {
        id:6,
        cat:'mobileapp',
        title:'Mobile Application',
        desc:'Mobile Application Description', 
        laptop: 'assets/img/portfolio/mob.png',
        image: 'assets/img/portfolio/mobileApp/pa1l.jpg'
    },
    {
        id:7,
        cat:'webapp',
        title:'Web Application',
        desc:'Web Application Description', 
        laptop: 'assets/img/portfolio/main-laptop-iframe7.png',
        image: 'assets/img/portfolio/webApp/gps.jpg'
    },
    
    {
        id:8,
        cat:'mobileapp',
        title:'Mobile Application',
        desc:'Mobile Application Description', 
        laptop: 'assets/img/portfolio/mob.png',
        image: 'assets/img/portfolio/mobileApp/realEastate.jpg'
    },

    {
        id:9,
        cat:'dataanalytics',
        title:'Data Analytics Application',
        desc:'Data Analytics Application Description', 
        laptop: 'assets/img/portfolio/main-laptop-iframe7.png',
        image: 'assets/img/portfolio/web2.png'
    },
    {
        id:10,
        cat:'webapp',
        title:'Web Application',
        desc:'Web Application Description', 
        laptop: 'assets/img/portfolio/main-laptop-iframe7.png',
        image: 'assets/img/portfolio/webApp/pms.jpg'
    },
    
    {
        id:11,
        cat:'creative',
        title:'Creative Application',
        desc:'Creative Application Description', 
        laptop: 'assets/img/portfolio/main-laptop-iframe7.png',
        image: 'assets/img/portfolio/creative/masco.jpg'
    },
    {
        id:12,
        cat:'digitalmarketing',
        title:'Digital Marketing Application',
        desc:'Digital Marketing Application Description', 
        laptop: 'assets/img/portfolio/main-laptop-iframe7.png',
        image: 'assets/img/portfolio/digitalMarketing/gtMatrix.jpg'
    },
    {
        id:13,
        cat:'digitalmarketing',
        title:'Digital Marketing Application',
        desc:'Digital Marketing Application Description', 
        laptop: 'assets/img/portfolio/main-laptop-iframe7.png',
        image: 'assets/img/portfolio/digitalMarketing/multipleAds.jpg'
    },
    
    {
        id:14,
        cat:'ecommercesolution',
        title:'eCommerce Solution',
        desc:'eCommerce Solution Description', 
        laptop: 'assets/img/portfolio/main-laptop-iframe7.png',
        image: 'assets/img/portfolio/digitalMarketing/multipleAds.jpg'
    },
];

export default PortfolioItem;
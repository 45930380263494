import React from 'react';
import '../../home/home.css';
import PortfolioData from './PortfolioData';

const PortfolioDetails = () => {

    return(
        <>
            <section className="insidePage" style={{ backgroundColor: "#2fb8e7" }}>
                <div className="container" data-aos="fade-up">
                    <header className="section-header">
                        <p className="text-white">Our Portfolio</p> <br />
                    </header>                    
                </div>              
            </section>
            
            <main className="main">
                
                {/* ======= Portfolio Section ======= */}
                <section id="portfolio" className="portfolio stickyPageMenu p-b-50" style={{ marginTop:'-50px' }}>
                    <PortfolioData/>
                </section>
                {/* ======= End of Portfolio Section ======= */}
            </main>
        </>
    ); 
}
export default PortfolioDetails;
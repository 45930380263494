import React, { useEffect, useState } from 'react';
import '../home.css';
import api from '../../api/api';
import config from '../../config';

import NoImage from '../../assets/images/noimage.jpg';

const OurClients = ({ setProgress }) => {
    // Client Logo List
    const [clients, setClient] = useState([]);
    useEffect(() => {
        setProgress(10);

        api.get(`/clients/landing`).then((res) => {
            if (res.status === 200) {
                setClient(res.data.data);
            }

            setProgress(100);
        });
    }, []);

    return (
        <>
            <style>
                {`
                    .our-valued-clients {
                        padding: 0px;
                    }
                    .our-valued-clients img {
                        border-radius: 20px;
                    }
                `}
            </style>

            <div >
                <div className="col-lg-12 text-center">
                    <header className="section-header">
                        <p className="blue-un">Our Valuable Clients</p>
                    </header>
                </div>
                <div className="container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                
                    <div className="row gy-4">

                        {
                            clients.map((item, idx) => (
                                <div key={idx} className="col-lg-3 col-md-3 col-sm-6 col-6 wow fadeInUp" data-aos-duration="2s" data-aos-delay="0.2s">
                                    <div className="valuedclients center our-valued-clients box1">
                                        <a href={item.website} target="_blank" rel="noopener noreferrer" className='tooltip-container'>
                                            <img 
                                                className="img-fluids" 
                                                alt={item.company_name}
                                                src={item.company_logo ? `${config.IMAGE_BASE_PATH}${item.company_logo}` : NoImage}
                                            />
                                            {item.company_logo ? '' : (
                                                <span className="tooltip-text">{item.company_name}</span>
                                            )}
                                        </a>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>
        </>
    );
};

export default OurClients;
import React, { } from 'react';
import '../home.css';

const OurProduct = () => {

    return (
        <>
            <div className="container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                <div className="row gx-0">
                    <header className="section-header">
                        <p >Our latest developed solution</p>
                        <div className="content">
                            <h5>
                            Our team of talented Data scientists, Data Engineer, software architecture, software engineers, multimedia specialists have been solving complex problems and creating custom sustainable solutions for our clients. We don’t sell products, we always promote solutions through technology. We can lift your business from garage to global.
                            </h5>
                        </div>
                    </header>
                    <div className="rotate col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                        <div className="content bounceClass">
                            <h2>Learning Management System </h2>
                            <p>Unlock the power of learning with LMS.Our LMS is a powerful cloud-based learning management system (LMS) designed to boost engagement and learning outcomes.</p>
                            <p>
                            All in-one system that brings together learning, growth, skills, management, and capabilities into a personalized experience. Whether you are training internal students, external audiences, or worldwide student/partners/staff, our inhouse developed LMS empowers you to thrive by tapping learning technology, proven to scale as your business grows.
                            </p>
                            <div className="text-center text-lg-start">
                                <a href="#a" className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                                <span>Read More</span>
                                <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                        <img src="assets/img/solution/edTech/web_solution_firm_lms.webp" className="img-fluid" alt="" />
                    </div>
                
                    <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                        <img src="assets/img/solution/eCommerce/web_solution_firm_ecommerce-multi-vendor.webp" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                        <div className="content bounceClass">
                            <h2>eCommerce Solution (Self-Hosted)</h2>
                            <p>If you care about your customer, business, cyber security , speed, Data sovereignty, and scalability then you should think about Self-Hosted eCommerce Solutions rather than 3rd party.</p>
                            <p>
                            Our solutions come with Single Vendor eCommerce, Multi-Vendor eCommerce Also, known as Multi-Vendor Marketplace. This solution comes with customer login & profile, vendor/seller login, profile, dashboard, admin panel. ecommerce front-end, marketplace admin login, employee login, profile, Dashboard, order management, inventory management, supplier management, Advanced analytics & reporting, Accounts module footprint, system configuration portal, payment gateway integration, security, scalability, API and many more.
                            </p>
                            <div className="text-center text-lg-start">
                                <a href="#a" className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                                <span>Read More</span>
                                <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OurProduct;
import { BrowserRouter, Route, Routes } from "react-router-dom";

import React, { useEffect, useState } from "react";
import Aos from "aos";

import "aos/dist/aos.css";
import './assets/css/style.css';
import './assets/css/bootstrap.min.css';
import './assets/css/bootstrap-icons/bootstrap-icons.css';
import "./App.css";

import Header from "./home/Header";
import Home from "./home/Home";

/* Solution Menu Start */
import EcommerceSolution from "./pages/solutionPages/EcommerceSolution";
import EdTechSolution from "./pages/solutionPages/EdTechSolution";
import SmallMediumBusiness from "./pages/solutionPages/SmallMediumBusiness";
import CorporateSolution from "./pages/solutionPages/CorporateSolution";
import ConsultancySolution from "./pages/solutionPages/ConsultancySolution";
import DigitalMarketingSEO from "./pages/solutionPages/DigitalMarketingSEO";
/* Solution Menu End */

/* Services Menu Start*/
import WebApplication from "./pages/servicesPages/WebApplication";
import SoftWareDevelopment from "./pages/servicesPages/SoftwareDevelopment";
import MobileApplication from "./pages/servicesPages/MobileApplication";
import CreativePage from "./pages/servicesPages/CreativePage";
import DigitalMarketing from "./pages/servicesPages/DigitalMarketing";
import DomainHosting from "./pages/servicesPages/DomainHosting";

import Portfolio from "./pages/portfolio/portfolioDetails";
import ContactUsPage from "./pages/helpCenter/ContactUsPage.";
/* Services Menu End*/

/* Company Menu  Start*/
import AboutUsPage from "./pages/aboutUs/AboutUsPage";
import TeamPage from "./pages/aboutUs/TeamPage";
import Clients from "./pages/company/ourClients/Clients";
import Partners from "./pages/company/ourPartners/Partners";
import TestimonialPage from "./pages/company/testimonial/TestimonialPage";

// Career Sub Menu
import CareerPage from "./pages/company/career/CareerPage";
import JobDetails from "./pages/company/career/JobDetails";
import ApplyJob from "./pages/company/career/ApplyJob";
/* Company Menu  End*/

// Blog 
import Blogs from "./pages/company/blogs/Blogs";
import Details from "./pages/company/blogs/Details";

// Knowledge Based 
import KnowledgeBasedIndex from "./pages/helpCenter/knowledgeBased/Index";
import CategoryBlogLayout from "./pages/helpCenter/knowledgeBased/partials/Layout";
import KnowledgeBasedBlogDetails from "./pages/helpCenter/knowledgeBased/BlogDetails";

import ScrollToTop from "./home/ScrollToTop";
import Footer from "./home/Footer";

import LoadingBar from "react-top-loading-bar";

function App() {

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Header />

        <LoadingBar
          color='#f11946'
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />

        <Routes>
          <Route path="/*" exact="/*" element={<Home setProgress={setProgress} />} />

          {/* Solution Menu */}
          <Route path="ecommerce-solution" exact="ecommerce-solution" element={<EcommerceSolution />} />
          <Route path="edtech" exact="edtech" element={<EdTechSolution />} />
          <Route path="small-to-medium-business" exact="small-to-medium-business" element={<SmallMediumBusiness />} />
          <Route path="corporate" exact="corporate" element={<CorporateSolution />} />
          <Route path="consultancy" exact="consultancy" element={<ConsultancySolution />} />
          <Route path="others" exact="others" element={<DigitalMarketingSEO />} />

          {/* Services Menu */}
          <Route path="web-application-details" exact="web-application-details" element={<WebApplication />} />
          <Route path="software-development-details" exact="software-development-details" element={<SoftWareDevelopment />} />
          <Route path="mobile-application-details" exact="mobile-application-details" element={<MobileApplication />} />
          <Route path="creative-details" exact="creative-details" element={<CreativePage />} />
          <Route path="digital-marketing-and-seo-details" exact="digital-marketing-and-seo-details" element={<DigitalMarketing />} />
          <Route path="domain-and-hosting-details" exact="domain-and-hosting-details" element={<DomainHosting />} />

          <Route path="portfolio" exact="portfolio" element={<Portfolio />} />
          <Route path="contact-us" exact="contact-us" element={<ContactUsPage />} />

          {/* Company Menu */}
          <Route path="company/our-clients" exact="our-clients" element={<Clients />} />
          <Route path="company/our-technology-partners" exact="our-technology-partners" element={<Partners />} />
          <Route path="company/about-us" exact="about-us-pages" element={<AboutUsPage />} />
          <Route path="company/our-teams" exact="our-teams" element={<TeamPage />} />
          <Route path="company/testimonials" exact="testimonials" element={<TestimonialPage setProgress={setProgress} />} />

          {/* Career */}
          <Route path="company/career" element={<CareerPage />} />
          <Route path="company/career/job-details/:slug" element={<JobDetails />} />
          <Route path="company/career/apply-job/:slug" element={<ApplyJob />} />

          {/* Blog */}
          <Route path="company/blogs" element={<Blogs setProgress={setProgress} />} />
          <Route path="company/blogs/details/:slug" element={<Details setProgress={setProgress} />} />
          <Route path="company/blogs/category/:slug" element={<Blogs setProgress={setProgress} />} />

          {/* Knowledged Base */}
          <Route path="help-center/knowledged-base" element={<KnowledgeBasedIndex setProgress={setProgress} />} />
          <Route path="help-center/knowledged-base/category/:categorySlug" element={<CategoryBlogLayout setProgress={setProgress} />} />
          <Route path="help-center/knowledged-base/:categorySlug/details/:blogSlug" element={<KnowledgeBasedBlogDetails setProgress={setProgress} />} />

        </Routes>

        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;

import React from 'react';
import '../home.css';

import { Link } from 'react-router-dom';

import { BsLaptop } from "react-icons/bs";
import { BsPencilSquare } from "react-icons/bs";
import { GoDeviceMobile } from "react-icons/go"; 
import { GoCode } from "react-icons/go"; 
import { AiOutlineCloudUpload } from "react-icons/ai"; 
import { HiOutlineSpeakerphone } from "react-icons/hi"; 


const OurServices = () => {
    return (
        <>
            <div className="container">
                <header className="section-header">
                    <p className='blue-un'>Our Services</p>
                </header>
                <div className="row gy-4">
            
                    <div loading="lazy" className="col-lg-4 col-md-6 aos" data-aos="slide-right" data-aos-duration="500" data-aos-delay="0.9s">
                        {/* <div className="service-box orange rotates"> */}
                        <div className="service-box orange">
                            <i className="icon">
                                <BsLaptop />
                            </i>
                            <h3>Web Application</h3>
                            <p>If you can imagine it, we can develop it. Don’t leave an idea in the pipeline, let’s convert the idea to a web application. Our highly skilled developers...</p>
                            <Link to="web-application-details" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
            
                    <div loading="lazy" className="col-lg-4 col-md-6 aos" data-aos="slide-up" data-aos-duration="500" data-aos-delay="0.9s">
                        <div className="service-box green">
                            <i className="icon">
                                <GoCode />
                            </i>
                            <h3>Software Development</h3>
                            <p>We are skilled & love solving problems through programming. We have been developing from inventory to ERP solution. Nothing is too small... </p>
                            <Link to="software-development-details" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                    
            
                    <div loading="lazy" className="col-lg-4 col-md-6 aos" data-aos="slide-left" data-aos-duration="500" data-aos-delay="0.9s">
                        <div className="service-box purple">
                        <i className="icon">
                            <GoDeviceMobile />
                        </i>
                        <h3>Mobile Application</h3>
                        <p>You have an idea to make a mobile app & not sure How to develop it . Don't worry Web Solution Firm  got your back with a dedicated app dev team...</p>
                        <Link to="mobile-application-details" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
            
                    <div loading="lazy" className="col-lg-4 col-md-6 aos" data-aos="slide-right" data-aos-duration="500" data-aos-delay="0.9s">
                        <div className="service-box red">
                        <i className="icon">
                            <BsPencilSquare />
                        </i>
                        <h3>Creative</h3>
                        <p>Every Creative & visual task of your business is an opportunity to communicate with your audiences. whether it’s a website, logo, UX & UI, brochure...</p>
                        <Link to='creative-details' target='_blank' className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
            
                    <div loading="lazy" className="col-lg-4 col-md-6 aos" data-aos="slide-up" data-aos-duration="500" data-aos-delay="0.9s">
                        <div className="service-box pink">
                        <i className="icon">
                            <HiOutlineSpeakerphone />
                        </i>
                        <h3>Digital Marketing & SEO</h3>
                        <p>We will develop creative, visual, & data-driven digital marketing to drive your business sales & profit, will look after your all digital Marketing... </p>
                        <Link to="digital-marketing-and-seo-details" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                    
                    <div loading="lazy" className="col-lg-4 col-md-6 aos" data-aos="slide-left" data-aos-duration="500" data-aos-delay="0.9s">
                        <div className="service-box blue">
                            <i className="icon">
                                <AiOutlineCloudUpload />
                            </i>
                            <h3>Domain & Hosting</h3>
                            <p>You absolutely deserve a good domain name and the best and most secure server to host your solution on cloud. We have servers across...</p>
                            <Link to="domain-and-hosting-details" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default OurServices;
import React from 'react';
import '../home.css';
import { Link } from 'react-router-dom';

const LandingPortfolio = () => {

    return (
        <>
            <div className="container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                <header className="section-header">
                    <p className='blue-un'>Portfolio</p>
                </header>
                <div className="row gy-4 portfolio-container">
                           
                    <div className="col-lg-6 col-md-6 portfolio-item filter-web" >
                        <div className='bgImg'>
                        <img alt="laptop-iframe" className="iframe" src="assets/img/portfolio/main-laptop-iframe7.png" />
                            <div className='box'>
                                <img src="assets/img/portfolio/webApp/cms.jpg" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>  
                    <div className="col-lg-6 col-md-6 portfolio-item filter-web" >
                        <div className='bgImg'>
                        <img alt="laptop-iframe" className="iframe" src="assets/img/portfolio/main-laptop-iframe7.png" />
                            <div className='box'>
                                <img src="assets/img/portfolio/webApp/gps.jpg" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>  
                    <div className="col-lg-6 col-md-6 portfolio-item filter-web" >
                        <div className='bgImg'>
                        <img alt="laptop-iframe" className="iframe" src="assets/img/portfolio/main-laptop-iframe7.png" />
                            <div className='box'>
                                <img src="assets/img/portfolio/webApp/eCommerce.jpg" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>  
                    <div className="col-lg-6 col-md-6 portfolio-item filter-web" >
                        <div className='bgImg'>
                        <img alt="laptop-iframe" className="iframe" src="assets/img/portfolio/main-laptop-iframe7.png" />
                            <div className='box'>
                                <img src="assets/img/portfolio/webApp/pms.jpg" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="textRight">
                        <Link to="portfolio" target="_blank" className="seeMoreBtn d-inline-flex align-items-center justify-content-center align-self-center">
                            <span>See More</span>
                            <i className="bi bi-arrow-right"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LandingPortfolio;
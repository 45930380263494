import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import JobPostList from "./JobPostList";

import "../../../home/home.css";
import './careerPage.css';

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -140;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

const CareerPage = () => {
    return (
        <>
            {/* <section className="insidePage" style={{ backgroundImage: `url("assets/img/contact.png")` }}> */}
            <section className="insidePage" style={{ backgroundColor: "#2fb8e7" }}>
                <div
                    className="container"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="0.9s"
                >
                    <p className="text-white text-center">
                        <span className="title">Career</span>
                        <span className='text-center subTitle'>
                            We would love to hear from you if you are a problem solver
                        </span>
                    </p>
                </div>
            </section>

            <main className="main">
                {/* ======= Sticky Menu Section ======= */}
                <section
                    id="portfolio"
                    className="portfolio stickyPageMenu"
                    style={{ marginTop: "-50px" }}
                >
                    <div style={{ backgroundColor: "#090046" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 d-flex ">
                                    <ul id="stickyMenu-flterss">
                                        <Link smooth to="#hr" scroll={(el) => scrollWithOffset(el)}>
                                            List of Current Jobs
                                        </Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ======= Content Section =======  */}
                <section
                    id="pricing"
                    className="pricing"
                    style={{ marginTop: "-50px" }}
                >
                    <div className="container pricing" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                        {/* Web Design Start */}
                        <div id="hr" className="row">
                            <JobPostList />
                        </div>
                        {/* Web Design End */}
                    </div>
                </section>

                {/* ======= End Contact Section ======= */}
            </main>
            {/* <section className=""></section> */}
        </>
    );
};

export default CareerPage;

import React from "react";
import './applyJob.css';
import ApplyForm from "./ApplyForm";
// import UploadFile from "./UploadFile";

// import bgHome from '../assets/images/bgHome.png';
import logo from '../../../assets/images/logo/logo.png';

const ApplyJob = () => {
  return (
    <>
      <section className="insidePage" style={{ backgroundColor: "#2fb8e7" }}>
        <h2 className='titleLogo text-center'>
            <img src={logo} className="img-fluid" alt="tght" />
        </h2> 
      </section>

      <main className="main">
        <section
          id="pricing"
          className="pricing"
          style={{ marginTop: "-50px" }}
        >
          <div className="container pricing" data-aos="fade-up">
            <div id="hr" className="row" data-aos="fade-left">
              <div
                className="col-lg-12 col-md-12"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="0.9s"
              >
                <div className="" style={{ marginTop: "10px" }}>
                    <div className="testbox">
                        <ApplyForm/>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ApplyJob;

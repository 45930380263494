import React, {useEffect, useState} from 'react';
import { useWindowScroll } from "react-use";
import '../home/home.css';
import FooterDetails from './FooterDetails';

// import { FaArrowUp } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";

const Footer = () => {

    const { y: pageYOffset } = useWindowScroll();
    const [visible, setVisiblity] = useState(false);

    useEffect(() => {
        if (pageYOffset > 0) {
            setVisiblity(true);
        } else {
            setVisiblity(false);
        }
    }, [pageYOffset]);

    const ScrollBottomToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

    if (!visible) {
        return false;
    }

    return (
        <>
            <footer id="footer" className="footer">
                <div className='body'>
                    <div className="footer-top" style={{ backgroundColor: "rgb(9 0 70)" }}>
                        <FooterDetails />
                    </div>
                </div>
            </footer>
            
            <a href="#x" onClick={ScrollBottomToTop} className="back-to-top d-flex align-items-center justify-content-center"
                >
                <i><FaAngleUp/></i>
            </a>
            
            
        </>
    );
};

export default Footer;
import React from "react";

import "../../../home/home.css";
import ClientData from "./ClientData";


const Clients = () => {

    return (
        <>
            <section className="insidePage" style={{ backgroundColor: '#2fb8e7' }}>
                <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                    <p className="text-white">
                        <span className='title text-center'>Our valuable clients</span> 
                        <span className='text-center subTitle'>
                            Trusted by big one and loved by everyone
                        </span>
                    </p>            
                </div>
            </section>

            <main className="main">
                <section id="clients" className="clients">
                    <div className="col-lg-12 text-center">
                        {/* <header className="section-header">
                            <p className="blue-un">Our Valuable Clients</p>
                        </header> */}
                    </div>
                    <div className="container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                        <div className="row gy-4">
                            <ClientData/>
                        </div>
                    </div>
                </section>
            </main>
            
        </>
    );
};

export default Clients;

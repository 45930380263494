import React from "react";
import "../../../home/home.css";

import a2Hosting from '../../../assets/img/partners/a2Hosting.webp';
import nameCheap from '../../../assets/img/partners/namecheap-logo.webp';
import microsoft from '../../../assets/img/partners/microsoft.webp';
import microsoftPartner from '../../../assets/img/partners/microsoft-partner.webp';
import redHat from '../../../assets/img/partners/redHat.webp';
import solutionSquad from '../../../assets/img/partners/solutionSquad.webp';

const PartnersData = () => {
    return (
        <>
            {[a2Hosting, nameCheap, microsoftPartner, microsoft, redHat, solutionSquad].map((imgSrc, idx) => (
                <div key={idx} className="col-lg-2 col-md-2 col-sm-6 col-6">
                    <div className="valuedclients center our-valued-clients box1">
                        <button className="img-button" type="button" aria-label={`Technology Partner ${idx + 1}`}>
                            <img className="img-fluids" src={imgSrc} alt={`Technology Partner ${idx + 1}`} />
                        </button>
                    </div>
                </div>
            ))}

            {/* <div className="col-lg-2 col-md-2 col-sm-2 col-6 wow fadeInUp" data-aos-duration="2s" data-aos-delay="0.2s">
                <div className="valuedclients center our-valued-clients box1">
                    <a href="#" rel="noreferrer">
                        <img className="img-fluids" src={a2Hosting} alt="a2hosting" />
                    </a>
                </div>
            </div> */}
        </>
    );
};

export default PartnersData;

import React, { useEffect, useState } from 'react';
import '../home.css';
import api from '../../api/api';
import config from '../../config';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/swiper.min.css'

import NoImage from '../../assets/images/noimage.jpg';

const Video = ({ setProgress }) => {    
    const [testimonials, setTestimonials] = useState([]);
    useEffect(() => {
        setProgress(10);

        api.get(`/testimonials/landing`).then((res) => {
            if (res.status === 200) {
                setTestimonials(res.data.data);
            }

            setProgress(100);
        });
    }, []);

    return (
        <>
            <div className=''>
                <div className="container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                    <header className="section-header">
                        <p className='blue-un'>Testimonial</p>
                    </header>
                    <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                        <Swiper
                            breakpoints={{
                                1200: {
                                  slidesPerView: 3,
                                },
                                991: {
                                  slidesPerView: 1,
                                },
                                768: {
                                  slidesPerView: 1,
                                },
                            }}
                            modules={[Autoplay]}
                            spaceBetween={15}
                            autoplay={true}
                        >
                            {
                                testimonials.map((item, idx) => (
                                    <SwiperSlide key={idx}>
                                        <div className="team">
                                            <div className="testimonial-item" >
                                                <div className="member">
                                                    <div className="member-img">
                                                        <img 
                                                            className="img-fluid" 
                                                            alt={item.name}
                                                            src={item.image ? `${config.IMAGE_BASE_PATH}${item.image}` : NoImage}
                                                        />
                                                    </div>
                                                    <div className="member-info">
                                                        <h4>{item.name}</h4>
                                                        <span>{item.designation}</span>
                                                        <p dangerouslySetInnerHTML={{ __html: item.note }}></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }

                            {/* <SwiperSlide>
                                <div className="team ">
                                    <div className="testimonial-item">
                                        <div className="member">
                                            <div className="member-img">
                                                <img src={lutfurHasan} className="img-fluid" alt="lutfurHasan" />
                                                <div className="social">
                                                    <a href="#a"><i className="bi bi-twitter"></i></a>
                                                    <a href="#a"><i className="bi bi-facebook"></i></a>
                                                    <a href="#a"><i className="bi bi-instagram"></i></a>
                                                    <a href="#a"><i className="bi bi-linkedin"></i></a>
                                                </div>
                                            </div>
                                            <div className="member-info">
                                                <h4>Lutfur Hasan</h4>
                                                <span>Founder and Director</span>
                                                <p>I have developed a CRM software by a Web Solution Firm. They are a highly technical and deadline oriented team. Team maintained good communication with me. Highly recommended.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> */}
                            
                        </Swiper>
                        <div className="swiper-pagination"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Video;